import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Nav, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart, faHistory, faEdit, faTrash, faUsers, faCheck, faTimes, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import api from '../utils/api';
import './UserProfile.css';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
const USER_API_BASE_PATH = process.env.REACT_APP_USER_API_BASE_PATH;

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [messageContent, setMessageContent] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [participantNotes, setParticipantNotes] = useState({});
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
    window.location.href = '/'; // 페이지 리다이렉트 및 새로 고침
  };

  return (
    <Container className="user-profile">
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Nav className="profile-nav" onSelect={(selectedKey) => setActiveTab(selectedKey)}>
            <Nav.Item>
              <Nav.Link eventKey="info">
                <FontAwesomeIcon icon={faUser} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="purchases">
                <FontAwesomeIcon icon={faShoppingCart} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="history">
                <FontAwesomeIcon icon={faHistory} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs="auto">
          <Button className="logout-button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> 로그아웃
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {activeTab === 'info' && <UserInfo />}
          {activeTab === 'purchases' && <UserPurchases />}
          {activeTab === 'history' && <UserHistory />}
        </Col>
      </Row>

      {/* 로그아웃 모달 */}
      {isLogoutModalOpen && (
        <div className="userprofile-modal-overlay" onClick={closeLogoutModal}>
          <div className="userprofile-modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="userprofile-modal-title">로그아웃 되었습니다.</h3>
            <div className="userprofile-modal-footer">
              <button className="userprofile-modal-button close" onClick={closeLogoutModal}>확인</button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await api.get(`${USER_API_BASE_PATH}/user-info/${userId}`);
        setUserInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('사용자 정보 불러오기 오류:', error);
        setError('사용자 정보를 불러오는 데 실패했습니다.');
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="user-info">
      {userInfo && (
        <div>
          <p><strong>이름:</strong> {userInfo.name}</p>
          <p><strong>이메일:</strong> {userInfo.user_email}</p>
          <p><strong>전화번호:</strong> {userInfo.hp ? 
            userInfo.hp.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') : 
            '전화번호 없음'}
          </p>
          <p><strong>레벨:</strong> {userInfo.level}</p>
          <p><strong>마일리지:</strong> {userInfo.level}</p>
        </div>
      )}
    </div>
  );
};

const UserPurchases = () => {
  const [productType, setProductType] = useState('sports');
  const [sportsPurchases, setSportsPurchases] = useState([]);
  const [travelPurchases, setTravelPurchases] = useState([]);
  const [selectedSportsPurchase, setSelectedSportsPurchase] = useState(null);
  const [selectedTravelPurchase, setSelectedTravelPurchase] = useState(null);
  const [showSportsModal, setShowSportsModal] = useState(false);
  const [showTravelModal, setShowTravelModal] = useState(false);

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await api.get(`${API_BASE_PATH}/user-purchases`);
        setSportsPurchases(response.data.sports_purchases || []);
        setTravelPurchases(response.data.travel_purchases || []);
      } catch (error) {
        console.error('구매 내역 불러오기 오류:', error);
      }
    };

    fetchPurchases();
  }, []);

  const handlePurchaseSportsClick = (purchase) => {
    setSelectedSportsPurchase(purchase);
    setShowSportsModal(true);
  };

  const handlePurchaseTravelClick = (purchase) => {
    setSelectedTravelPurchase(purchase);
    setShowTravelModal(true);
  };

  const closeSportsModal = () => {
    setShowSportsModal(false);
    setSelectedSportsPurchase(null);
  };

  const closeTravelModal = () => {
    setShowTravelModal(false);
    setSelectedTravelPurchase(null);
  };

  return (
    <div className="user-history">
      <h2>구매 이력</h2>
      <div className="product-type-selector">
        <button
          className={`type-button ${productType === 'sports' ? 'active' : ''}`}
          onClick={() => setProductType('sports')}
        >
          스포츠 학습
        </button>
        <button
          className={`type-button ${productType === 'travel' ? 'active' : ''}`}
          onClick={() => setProductType('travel')}
        >
          여행 상품
        </button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>상품명</th>
            <th>구매일</th>
            <th>가격</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {productType === 'sports' &&
            sportsPurchases.map((purchase) => (
              <tr key={purchase.id} onClick={() => handlePurchaseSportsClick(purchase)}>
                <td>{`${purchase.sport} - ${purchase.course}`}</td>
                <td>{new Date(purchase.updated_at).toLocaleDateString()}</td>
                <td>{Number(purchase.selected_price).toLocaleString()}원</td>
                <td className={`status ${purchase.is_paid ? 'paid' : 'unpaid'}`}>
                  {purchase.is_paid ? '결제완료' : '미결제'}
                </td>
              </tr>
            ))}
          {productType === 'travel' &&
            travelPurchases.map((purchase) => (
              <tr key={purchase.id} onClick={() => handlePurchaseTravelClick(purchase)}>
                <td>{purchase.travel_product_name}</td>
                <td>{new Date(purchase.updated_at).toLocaleDateString()}</td>
                <td>{Number(purchase.selected_price).toLocaleString()}원</td>
                <td className={`status ${purchase.is_paid ? 'paid' : 'unpaid'}`}>
                  {purchase.is_paid ? '결제완료' : '미결제'}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showSportsModal && selectedSportsPurchase && (
        <div className="modal-overlay" onClick={closeSportsModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-icon" onClick={closeSportsModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3 className="modal-title">스포츠 구매 상세 정보</h3>
            <div className="modal-body">
              <table className="purchase-details-table">
                <tbody>
                  <tr>
                    <td className="info-label">스포츠</td>
                    <td className="info-value">{selectedSportsPurchase.sport}</td>
                  </tr>
                  <tr>
                    <td className="info-label">코스</td>
                    <td className="info-value">{selectedSportsPurchase.course}</td>
                  </tr>
                  <tr>
                    <td className="info-label">장소</td>
                    <td className="info-value">{selectedSportsPurchase.location}</td>
                  </tr>
                  <tr>
                    <td className="info-label">날짜</td>
                    <td className="info-value">{selectedSportsPurchase.date}</td>
                  </tr>
                  <tr>
                    <td className="info-label">시간</td>
                    <td className="info-value">{selectedSportsPurchase.start_time.slice(0, 5)} - {selectedSportsPurchase.end_time.slice(0, 5)}</td>
                  </tr>
                  <tr>
                    <td className="info-label">가격</td>
                    <td className="info-value">{Number(selectedSportsPurchase.selected_price).toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <td className="info-label">참가자 수</td>
                    <td className="info-value">{selectedSportsPurchase.participants}</td>
                  </tr>
                  <tr>
                    <td className="info-label">상태</td>
                    <td className={`info-value status ${selectedSportsPurchase.is_paid ? 'paid' : 'unpaid'}`}>
                      {selectedSportsPurchase.is_paid ? '결제완료' : '미결제'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showTravelModal && selectedTravelPurchase && (
        <div className="modal-overlay" onClick={closeTravelModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-icon" onClick={closeTravelModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3 className="modal-title">여행 상품 구매 상세 정보</h3>
            <div className="modal-body">
              <table className="purchase-details-table">
                <tbody>
                  <tr>
                    <td className="info-label">상품명</td>
                    <td className="info-value">{selectedTravelPurchase.travel_product_name}</td>
                  </tr>
                  <tr>
                    <td className="info-label">출발일</td>
                    <td className="info-value">{selectedTravelPurchase.start_date}</td>
                  </tr>
                  <tr>
                    <td className="info-label">도착일</td>
                    <td className="info-value">{selectedTravelPurchase.end_date}</td>
                  </tr>
                  <tr>
                    <td className="info-label">목적지</td>
                    <td className="info-value">{selectedTravelPurchase.location}</td>
                  </tr>
                  <tr>
                    <td className="info-label">가격</td>
                    <td className="info-value">{Number(selectedTravelPurchase.selected_price).toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <td className="info-label">상태</td>
                    <td className={`info-value status ${selectedTravelPurchase.is_paid ? 'paid' : 'unpaid'}`}>
                      {selectedTravelPurchase.is_paid ? '결제완료' : '미결제'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UserHistory = () => {
  const [productType, setProductType] = useState('sports');
  const [sportsCourses, setSportsCourses] = useState([]);
  const [travelProducts, setTravelProducts] = useState([]);
  const [userLevel, setUserLevel] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [messageContent, setMessageContent] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [expandedCourse, setExpandedCourse] = useState(null);
  const [participantNotes, setParticipantNotes] = useState({});
  const navigate = useNavigate();

  const fetchSales = useCallback(async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await api.get(`${API_BASE_PATH}/user-sales`);
      setSportsCourses(response.data.sports_courses);
      setTravelProducts(response.data.travel_products);
    } catch (error) {
      console.error('판매 내역 불러오기 오류:', error);
    }
  }, []);

  useEffect(() => {
    fetchSales();

    // 사용자 레벨을 가져오는 로직
    const level = localStorage.getItem("level");
    setUserLevel(level);
  }, [fetchSales]);

  const member_manage = async (course) => {
    try {
      setSelectedCourse(course);
      setParticipants(course.apply_participants);
      setShowMemberModal(true);
    } catch (error) {
      console.error('참가자 정보 가져오기 실패:', error);
      setMessageContent('참가자 정보를 가져오는 데 실패했습니다.');
      setShowMessage(true);
    }
  };  

  const handleEdit = (item, type) => {
    if (type === 'sports') {
      window.location.href = `/input?courseId=${item.id}`;
    } else if (type === 'travel') {
      window.location.href = `/travel_products_input?productId=${item.id}`;
    }
  };

  const handleDelete = (id, type) => {
    setItemToDelete(id);
    setDeleteType(type);
    setIsDeleteModalOpen(true);
  };

  const handleEndCourse = async (courseId) => {
    if (window.confirm('정말로 이 강습을 종료하시겠습니까? 이 작업은 되돌릴 수 없습니다.')) {
      try {
        const response = await api.post(`${API_BASE_PATH}/end-course/${courseId}/`);
        if (response.data.code === "0000") {
          setMessageContent(response.data.message);
          setShowMessage(true);
          setShowMemberModal(false);
          fetchSales(); // 강습 목록 새로고침
        } else {
          setMessageContent(response.data.message || '강습 종료 중 오류가 발생했습니다.');
          setShowMessage(true);
        }
      } catch (error) {
        console.error('강습 종료 실패:', error);
        if (error.response) {
          setMessageContent(error.response.data.message || '강습 종료 중 오류가 발생했습니다.');
        } else {
          setMessageContent('네트워크 오류가 발생했습니다.');
        }
        setShowMessage(true);
      }
    }
  };

  const confirmDelete = async () => {
    try {
      if (deleteType === 'sports') {
        await api.delete(`${API_BASE_PATH}/delete-sports-schedule/${itemToDelete}/`);
        setMessageContent('강습이 성공적으로 삭제되었습니다.');
      } else if (deleteType === 'travel') {
        await api.delete(`${API_BASE_PATH}/delete-product/${itemToDelete}/`);
        setMessageContent('상품이 성공적으로 삭제되었습니다.');
      }
      setIsDeleteModalOpen(false);
      setShowMessage(true);
      // 상품 목록 새로고침
      fetchSales();
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
      setMessageContent('삭제 중 오류가 발생했습니다.');
      setShowMessage(true);
    }
  };

  const handleParticipantClick = (participant, course) => {
    setExpandedCourse(prevExpandedCourse => 
      prevExpandedCourse === `${course.id}-${participant.user_id}` ? null : `${course.id}-${participant.user_id}`
    );
    if (!participantNotes[participant.user_id]) {
      fetchParticipantNotes(participant.user_id, course.id);
    }
  };

  const fetchParticipantNotes = async (participantId, courseId) => {
    // participantId가 undefined인 경우 처리
    if (participantId === undefined) {
      console.error('참가자 ID가 undefined입니다.');
      return;
    }

    const participantIdString = String(participantId);

    try {
      //console.log(`${participantIdString} 참가자의 노트를 가져오는 중...`);
      const response = await api.get(`${API_BASE_PATH}/past-courses/${participantIdString}`);

      if (response.data.length > 0 && response.data[0].session_notes.length > 0) {
      
        setParticipantNotes(prev => {
          //console.log('이전 participantNotes:', prev);
          //console.log('서버에서 받은 노트:', response.data[0].session_notes[0].instructor_note);
          
          const newNotes = {
            ...prev,
            [participantIdString]: response.data[0].session_notes[0].instructor_note || prev[participantIdString] || ''
          };
          
          //console.log('새로운 participantNotes:', newNotes);
          return newNotes;
        });
      }
    } catch (error) {
      console.error(`참가자 ${participantIdString}의 노트 가져오기 실패:`, error);
    }
  };

  const handleNoteChange = (participantId, note) => {
    setParticipantNotes(prev => ({
      ...prev,
      [participantId]: note
    }));
  };

  const handleNoteSave = async (participantId, courseId) => {
    const currentNote = participantNotes[participantId] || '';

    if (!currentNote.trim()) {  // 공백만 있는 경우도 저장하지 않음
      setMessageContent('저장할 내용이 없습니다.');
      setShowMessage(true);
      return;
    }

    try {
      let session_type = null;
      if (selectedCourse.day == null && selectedCourse.is_theory === true) {
        session_type = "theory";
      } else {
        session_type = `Day${selectedCourse.day}`;
      }

      await api.post(`${API_BASE_PATH}/complete-session/`, {
        sportsCourseId: courseId,
        participantId: participantId,
        session_number: session_type,
        instructor_note: currentNote
      });

      setMessageContent('수강생 기록이 성공적으로 저장되었습니다.');
      setShowMessage(true);

    } catch (error) {
      console.error('수강생 기록 저장 중 오류 발생:', error);
      setMessageContent('수강생 기록 저장 중 오류가 발생했습니다.');
      setShowMessage(true);
    }
  };

  return (
    <div className="user-history">
      <h2>생성 이력</h2>
      <div className="product-type-selector">
        <button
          className={`type-button ${productType === 'sports' ? 'active' : ''}`}
          onClick={() => setProductType('sports')}
        >
          스포츠 학습
        </button>
        <button
          className={`type-button ${productType === 'travel' ? 'active' : ''}`}
          onClick={() => {setProductType('travel'); setShowMemberModal(false);}}
        >
          여행 상품
        </button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>강습명</th>
            <th>날짜</th>
            <th>액션</th>
          </tr>
        </thead>
        <tbody>
          {productType === 'sports' &&
            sportsCourses.map((course) => (
              <tr key={course.id}>
                <td>{`${course.sport} - ${course.course}`}</td>
                <td>{course.date}</td>
                <td className="action-cell">
                {course.status === 'approved' ? (
                    <FontAwesomeIcon 
                      icon={faUsers} 
                      onClick={() => member_manage(course)} 
                      className="action-icon member-icon"
                      title="회원 관리"
                    />
                  ) : (
                    <FontAwesomeIcon 
                      icon={faEdit} 
                      onClick={() => handleEdit(course, 'sports')} 
                      className="action-icon edit-icon"
                    />
                  )}
                  {userLevel == 22 && (
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      onClick={() => handleDelete(course.id, 'sports')} 
                      className="action-icon delete-icon"
                    />
                  )}
                </td>
              </tr>
            ))}
          {productType === 'travel' &&
            travelProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {product.start_date}<br />
                  ~ {product.end_date}
                </td>
                <td className="action-cell">
                  <FontAwesomeIcon 
                    icon={faEdit} 
                    onClick={() => handleEdit(product, 'travel')} 
                    className="action-icon edit-icon"
                  />
                  {userLevel == 22 && (
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      onClick={() => handleDelete(product.id, 'travel')} 
                      className="action-icon delete-icon"
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        className="delete-modal"
        overlayClassName="delete-modal-overlay"
      >
        <div className="delete-modal-content">
          <h2>삭제 확인</h2>
          <p>{deleteType === 'sports' ? '정말로 이 강습을 삭제하시겠습니까?' : '정말로 이 상품을 삭제하시겠습니까?'}</p>
          <div className="delete-modal-buttons">
            <button onClick={confirmDelete} className="confirm-button">삭제</button>
            <button onClick={() => setIsDeleteModalOpen(false)} className="cancel-button">취소</button>
          </div>
        </div>
      </Modal>
      {showMessage && (
        <div className="message-box">
          <p style={{ whiteSpace: 'pre-line' }}>{messageContent}</p>
          <button className="close-button" onClick={() => setShowMessage(false)}>
            닫기
          </button>
        </div>
      )}
      {showMemberModal && selectedCourse && (
        <div className="modal-overlay" onClick={() => setShowMemberModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="modal-title">[{selectedCourse.sport}] {selectedCourse.course} 강습</h3>
            <div className="modal-body">
              <h4 className="participant-list-title">수강생 목록</h4>
              <ul className="participant-list">
                {participants.map((participant, index) => (
                  <li 
                    key={index} 
                    className={`participant-item ${expandedCourse === `${selectedCourse.id}-${participant.user_id}` ? 'expanded' : ''}`}
                    onClick={() => handleParticipantClick(participant, selectedCourse)}
                  >
                    <span className="participant-name">{participant.name}</span>
                    {expandedCourse === `${selectedCourse.id}-${participant.user_id}` && (
                      <div className="participant-notes" onClick={(e) => e.stopPropagation()}>
                        <textarea
                          id={`participant-note-${participant.id}`}
                          value={participantNotes[participant.user_id]}
                          onChange={(e) => handleNoteChange(participant.user_id, e.target.value)}
                          placeholder="수강생 노트를 입력하세요..."
                        />
                        <button onClick={(e) => {
                          e.stopPropagation();
                          handleNoteSave(participant.user_id, selectedCourse.id);
                        }}>저장</button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button className="modal-button end-course" onClick={() => handleEndCourse(selectedCourse.id)}>강습 종료</button>
              <button className="modal-button close" onClick={() => setShowMemberModal(false)}>닫기</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
